<template>
  <div>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label-for="mc-email"
            label="Curso"
        >
        
          <v-select
            v-model="selected_course"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            :reduce="course => course.id"
            placeholder="Seleccionar Curso"
          />
          
        </b-form-group>  
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Search"
            label-for="mc-first-name"
          >
              <b-form-input
                v-model="search"
                id="mc-first-name"
                placeholder="Search by name and email"
                name="search"
              />
          </b-form-group>
          
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mt-2"
        >
          <b-button    
            variant="primary"
            class="float-right"
            @click="filterListing"
          >
            Search
      </b-button>
          
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mt-2"
        >
          <b-button    
            variant="primary"
            class="float-left"
            @click="resetListing"
          >
            Reset
      </b-button>
          
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  <b-card-code
    title="Pagos"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    <b-col
      md="12"
      class="my-1"
    >
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/add-payment"
        v-if="checkAddPermission"
      >
            Añadir Pago
      </b-button>
    </b-col>
    <b-table
      :fields="fields"
      :items="paymentLists"
      responsive
      class="mb-0"
    >
      <template #head(status)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>
      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(status)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        <!--<b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip> -->
      </template>
      <template #cell(username)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="base_url+''+data.item.user.user_detail.attachment_url"
              :text="avatarText(data.item.user.user_detail.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.user.user_detail.name }} {{data.item.user.user_detail.surname}}
          </span>
          <small class="text-muted">{{ data.item.user.email }}</small>
        </b-media>
      </template>
      <!-- A custom formatted column -->
      <template #cell(course)="data">
        <span class="text-nowrap">{{ data.item.course.name }}</span>
      </template>
      <template #cell(total_amount)="data">
        <span class="text-nowrap">€{{ data.value }}</span>
      </template>
      <template #cell(paid_amount)="data">
        <span class="text-nowrap">€{{ data.value }}</span>
      </template>
      <template #cell(balance)="data">
        <span class="text-nowrap" v-if="parseInt( data.item.total_amount) - parseInt(data.item.paid_amount) == 0">
          <b-badge pill variant="light-success"> Paid </b-badge>
        </span>
        <span class="text-nowrap" v-else>
          -€{{parseInt( data.item.total_amount) - parseInt(data.item.paid_amount)}}
        </span>
        
      </template>
      <template #cell(issue_date)="data">
        <span class="text-nowrap">{{data.value | formatDate }}</span>
      </template>
      <template  #cell(id)="data">
          <div class="text-nowrap">
            <b-button v-if="checkEditPermission" variant="outline-primary" :to="'/edit-payment/'+data.value" size="sm"><b-icon icon="pencil-square"></b-icon></b-button>
            <b-button v-if="checkDeletePermission" class="ml-1" @click="deletePayment(data.value)" variant="outline-danger" size="sm"><b-icon icon="archive-fill"></b-icon></b-button>
         </div>
              
     </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BFormInput, BCardHeader, BAvatar, BMedia, BFormGroup, BCardBody, BCard, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import StudentService from '@/services/student.service.js';
import vSelect from 'vue-select'
import CourseService from '@/services/course.service.js';
import UserService from '@/services/user.service.js';
import PaymentService from '@/services/payment.service.js';
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCard,
    BFormGroup,
    vSelect,
    BAvatar,
    BMedia,
    
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format('D MMM YYYY');
    }
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'status' },
        { key: 'username', label: 'Username' },
        { key: 'course', label: 'Curso' },
        { key: 'total_amount', label: 'Total' },
        { key: 'paid_amount', label: 'Pagado' },
        // A regular column
        { key: 'balance', label: 'Balance' },
        
        // A regular column
        { key: 'issue_date', label: 'Fecha de Pago' },
        { key: 'id', label: 'Acción' },
      ],
      courses: [],
      course_id: '',
      search: '',
      paymentLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
      selected_course: '',
      search: '',
      checkAddPermission: this.$store.getters.checkPermission('add-payment'),
      checkEditPermission: this.$store.getters.checkPermission('edit-payment'),
      checkDeletePermission: this.$store.getters.checkPermission('delete-payment'),
      base_url: 'https://aula.anclademia.com/public/uploads/public/userProfile/'
      //base_url: 'http://localhost:8000/uploads/public/userProfile/'
    }
  },
  methods: {
    
    getPayments() {
      this.showOverlay = true;
      PaymentService.listing(
        this.currentPage,
        this.selected_course,
        this.search
        ).then(response => {
          this.paymentLists = response.data.data.payments.data;
          this.rows = response.data.data.payments.total;
          this.currentPage = response.data.data.payments.current_page
          this.path = response.data.data.payments.path

          this.pageCount = response.data.data.payments.last_page;
          this.currentPage = response.data.data.payments.current_page;
          this.perPageRecord = response.data.data.payments.per_page;
          this.toRecordCount = response.data.data.payments.to;
          this.fromRecordCount = response.data.data.payments.from;
          this.totalRecordCount = response.data.data.payments.total;
          this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
          this.showOverlay = false;
        }).catch(error => {
          this.showOverlay = false;
          console.log(error);
        });
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    deletePayment(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          PaymentService.delete(id).then(response => {
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'Payment has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  
                  const paymentIndex = this.paymentLists.findIndex(payment => payment.id === id);
                  this.paymentLists.splice(paymentIndex, 1);   
                  this.showOverlay = false;
                    
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
          
        }
      })
    },
    resetListing() {
      this.search = '';
      this.selected_course = '';
      this.currentPage = 1
      this.getPayments();
    },
    resolveInvoiceStatusVariantAndIcon(status) {
      if (status === 'partial') return { variant: 'warning', icon: 'PieChartIcon' }
      if (status === 'full') return { variant: 'success', icon: 'CheckCircleIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    },
    resolveClientAvatarVariant(status) {
      if (status === 'Partial Payment') return 'primary'
      if (status === 'Paid') return 'danger'
      if (status === 'Downloaded') return 'secondary'
      if (status === 'Draft') return 'warning'
      if (status === 'Sent') return 'info'
      if (status === 'Past Due') return 'success'
      return 'primary'
    },
    filterListing() {
      this.currentPage = 1
      this.getPayments();
    },
    changePage(event) {
      this.getPayments(this.currentPage);
    },
    getCourses()
    {
      CourseService.getAll().then(response => {
        this.courses = response.data.data.courses;  
      }).catch(error => {
        console.log(error)
      });
    },
  },
  created() {
    this.getPayments(this.currentPage);
    this.getCourses();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
